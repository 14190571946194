import { useMemo } from "react"

import { useAxios } from "../context/axios"
import { CustomersService } from "./customers"
import { SingupService } from "./signup"
import { TenantsService } from "./tenants"
import { UsersService } from "./users"

export const useSingupService = () => {
  const axios = useAxios()

  return useMemo(() => SingupService(axios), [axios])
}

export const useUsersService = () => {
  const axios = useAxios()

  return useMemo(() => UsersService(axios), [axios])
}

export const useCustomersService = () => {
  const axios = useAxios()

  return useMemo(() => CustomersService(axios), [axios])
}

export const useTenantsService = () => {
  const axios = useAxios()

  return useMemo(() => TenantsService(axios), [axios])
}
