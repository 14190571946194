import { useMemo } from "react"
import { Link, LinkProps } from "react-router-dom"

import { useSettings } from "../../context/theme"

export const RouterLink = (props: LinkProps) => {
  const { theme } = useSettings()

  const style = useMemo<LinkProps["style"]>(() => ({
    textDecoration: "none",
    color: theme.palette.primary.main,
    font: theme.typography.fontFamily,
  }), [theme.palette.primary.main, theme.typography.fontFamily])

  return (
    <Link {...props} style={style}>
      {props.children}
    </Link>
  )
}
