import { CreateCustomerPayload, CreateCustomerResponse, Customer } from "@contextualio/contextual-silo-auth"
import { AxiosInstance } from "axios"

export const CustomersService = (axiosInstance: AxiosInstance) => {

  const getCustomer = async (): Promise<Customer> => {
    const response = await axiosInstance.request({
      method: "get",
      url: "customers",
    })

    return response.data
  }

  const createCustomer = async (customer: CreateCustomerPayload): Promise<CreateCustomerResponse> => {
    const response = await axiosInstance.request({
      method: "post",
      url: "customers",
      data: customer,
    })

    return response.data
  }

  return {
    getCustomer,
    createCustomer,
  }
}
