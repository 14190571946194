import { CreateCustomerPayload } from "@contextualio/contextual-silo-auth"
import { Box, MenuItem, TextField, Typography } from "@mui/material"
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react"

import { DeepPartial, DescriptionOptions } from "../../types/pages"
import { findErrorByName, getErrorMessage, getFormErrors, getValueByKey } from "../../utils/validate-form"
import { CustomButton } from "../Button"

type Props = {
  loading?: boolean
  onSubmit: (data: CreateCustomerPayload) => Promise<void>
}

export const FourthStep = ({ loading, onSubmit }: Props) => {
  const [data, setData] = useState<DeepPartial<CreateCustomerPayload> | CreateCustomerPayload>()

  const errors = useMemo(() => getFormErrors(CreateCustomerPayload, data), [data])

  const findError = useCallback((name: string) => !!getValueByKey(data, name) && !!findErrorByName(errors, name), [data, errors])

  const findErrorMessage = useCallback((name: string) => getErrorMessage(findErrorByName(errors, name)), [errors])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target

    setData((prevData) => ({ ...prevData, [name]: value }))
  }, [])

  const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!data) return

    const parsed = CreateCustomerPayload.safeParse(data)

    if (!parsed.success) return

    await onSubmit(parsed.data)
  }, [data, onSubmit])

  return (
    <>
      <Typography variant="h5" mb={3}>
        Tell us about your business
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          name="name"
          label={"Business Name"}
          value={data?.name}
          onChange={handleChange}
          error={findError("name")}
          helperText={findErrorMessage("name")}
        />
        <TextField
          select
          name="customerIntent"
          label={"I’m considering Contextual..."}
          value={data?.customerIntent}
          onChange={handleChange}
          error={findError("customerIntent")}
          helperText={findErrorMessage("customerIntent")}
        >
          {DescriptionOptions.options.map((desc) =>
            <MenuItem key={desc} value={desc}> {desc} </MenuItem>,
          )}
        </TextField>
        <CustomButton
          loading={loading}
          type="submit"
          fullWidth
          disabled={!!errors?.length}
        >
          Next
        </CustomButton>
      </Box>
    </>
  )
}
