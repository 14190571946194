import { CreateUserPayload } from "@contextualio/contextual-silo-auth"
import { Box, Checkbox, FormControlLabel, FormGroup, MenuItem, TextField, Typography } from "@mui/material"
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react"

import { useAuth0 } from "../../context/auth0"
import { DeepPartial, RoleOptions } from "../../types/pages"
import { findErrorByName, getErrorMessage, getFormErrors, getValueByKey } from "../../utils/validate-form"
import { CustomButton } from "../Button"
import { RouterLink } from "../Link"

type Props = {
  loading?: boolean
  onSubmit: (data: CreateUserPayload) => Promise<void>
}

export const SecondStep = ({ loading, onSubmit }: Props) => {
  const { user } = useAuth0()

  const [data, setData] = useState<DeepPartial<CreateUserPayload> | CreateUserPayload>()

  const initialUserData = useMemo<Partial<Pick<CreateUserPayload, "email">>>(() => ({
    email: user?.email,
  }), [user?.email])

  const errors = useMemo(() => getFormErrors(CreateUserPayload, { ...data, ...initialUserData }), [data, initialUserData])

  const findError = useCallback((name: string) => !!getValueByKey(data, name) && !!findErrorByName(errors, name), [data, errors])

  const findErrorMessage = useCallback((name: string) => getErrorMessage(findErrorByName(errors, name)), [errors])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target

    setData((prevData) => ({ ...prevData, [name]: value }))
  }, [])

  const handleChecked = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target

    setData((prevData) => ({ ...prevData, [name]: checked }))
  }, [])

  const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!data) return

    const parsed = CreateUserPayload.safeParse({ ...data, ...initialUserData })

    if (!parsed.success) return

    await onSubmit(parsed.data)
  }, [data, initialUserData, onSubmit])

  return (
    <>
      <Typography variant="h5" mb={3}>
        Tell us about you
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          disabled={!!user?.email}
          name={"email"}
          label={"Work Email"}
          value={user?.email}
          onChange={handleChange}
          error={findError("user.email")}
          helperText={findErrorMessage("user.email")}
        />
        <TextField
          name={"fullName"}
          label={"Full Name"}
          value={data?.fullName}
          onChange={handleChange}
          error={findError("user.fullName")}
          helperText={findErrorMessage("user.fullName")}
        />
        <TextField
          select
          name="userBusinessRole"
          value={data?.userBusinessRole}
          label={"My role is best described as..."}
          onChange={handleChange}
          error={findError("user.userBusinessRole")}
          helperText={findErrorMessage("user.userBusinessRole")}
        >
          {RoleOptions.options.map((role) =>
            <MenuItem key={role} value={role}> {role} </MenuItem>,
          )}
        </TextField>
        <FormGroup sx={{ textAlign: "left", mt: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                name={"termsAccepted"}
                checked={!!data?.termsAccepted}
                onChange={handleChecked}
              />
            }
            label={
              <Typography>Agree to <RouterLink to={"https://www.contextual.io/terms"} target="_blank">terms of use</RouterLink> and <RouterLink to={"https://www.contextual.io/privacy"} target="_blank">privacy policy</RouterLink></Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                name={"marketingOptIn"}
                checked={!!data?.marketingOptIn}
                onChange={handleChecked}
              />
            }
            label={
              <Typography>I’d like to receive emails with news & updates from Contextual</Typography>
            }
          />
        </FormGroup>
        <CustomButton
          loading={loading}
          type="submit"
          fullWidth
          disabled={!!errors?.length}
        >
          Next
        </CustomButton>
      </Box>
    </>
  )
}
