import { CreateUserPayload, TenantInvitations, User } from "@contextualio/contextual-silo-auth"
import { AxiosInstance } from "axios"

export const UsersService = (axiosInstance: AxiosInstance) => {

  const getUser = async (): Promise<User> => {
    const response = await axiosInstance.request({
      method: "get",
      url: "me",
    })

    return response.data
  }

  const createUser = async (user: CreateUserPayload): Promise<User> => {
    const response = await axiosInstance.request({
      method: "post",
      url: "users",
      data: user,
    })

    return response.data
  }

  const resendInvitation = async (): Promise<User> => {
    const response = await axiosInstance.request({
      method: "put",
      url: "resend-invite",
    })

    return response.data
  }

  const getLastTenantInvitation = async (tenantId: string): Promise<TenantInvitations> => {
    const response = await axiosInstance.request({
      method: "get",
      url: `users/${tenantId}/invitation`,
    })

    return response.data
  }

  return {
    getUser,
    createUser,
    resendInvitation,
    getLastTenantInvitation,
  }
}
