import { CreateSubscriptionPayload, TenantReservation } from "@contextualio/contextual-silo-auth"
import { useCallback, useEffect, useState } from "react"

import { LoadingBox } from "../../components/Loading"
import { CreateTenant } from "../../components/Tenants/create"
import { useAlert } from "../../context/alert"
import { useSingupService, useTenantsService } from "../../providers"

type Props = {
}

export const NewTenantPage = ({ }: Props) => {
  const { createSubscription } = useSingupService()
  const { setAlert } = useAlert()
  const { getTenantReservations } = useTenantsService()

  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  const [reservations, setReservations] = useState<TenantReservation[]>()

  useEffect(() => {
    const list = async () => {
      setLoading(true)
      try {
        const rsp = await getTenantReservations()
        setReservations(rsp)
      } catch (error) {
        setAlert({
          severity: "error",
        })
      } finally {
        setLoading(false)
      }
    }
    list()
  }, [getTenantReservations, setAlert])

  const handleSubmit = useCallback(async (data: CreateSubscriptionPayload) => {
    try {
      await createSubscription(data)
      setSuccess(true)
    } catch (error) {
      setAlert({
        message: "Error creating tenant",
        severity: "error",
      })
    }
  }, [createSubscription, setAlert])

  if (loading) {
    return <LoadingBox />
  }

  return <CreateTenant label="Create a new Tenant" onSubmit={handleSubmit} success={success} reservations={reservations} setReservations={setReservations} withCardWrapper />
}
