import { CircularProgress, Stack, Typography } from "@mui/material"
import { MouseEvent } from "react"

import { useAuth0 } from "../../context/auth0"
import { RouterLink } from "../Link"

type Props = {
  loading?: boolean
  onResendEmail: (event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => Promise<void>
}

export const ThirdStep = ({ loading, onResendEmail }: Props) => {
  const { user } = useAuth0()

  if (loading) {
    return <CircularProgress size={"100%"} />
  }

  return (
    <Stack spacing={1} minHeight={"50vh"} justifyContent={"center"}>
      <Typography variant="h5">
        We’ve sent a verification email to {user?.email}
      </Typography>
      <Typography variant="body1">
        Click the Link in your email to continue.
      </Typography>
      <Typography variant="body1">
        Don’t see it? <RouterLink to={"#"} onClick={onResendEmail}>Send again</RouterLink>
      </Typography>
    </Stack>
  )
}
