import createAuth0Client, { Auth0ClientOptions, getIdTokenClaimsOptions, GetTokenSilentlyOptions, IdToken, LogoutOptions, User } from "@auth0/auth0-spa-js"
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { Fallback } from "../components/Fallback"

type AllowedParam = string | undefined

const DEFAULT_REDIRECT_CALLBACK: OnRedirectCallback = () =>
  window.history.replaceState({}, document.title, window.location.pathname)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppState = any
type OnRedirectCallback = (appState?: AppState) => void

type Context = {
  isAuthenticated?: boolean
  loading: boolean
  token?: string
  user?: User
  returnUrl: string | undefined
  getIdTokenClaims: (options?: getIdTokenClaimsOptions) => Promise<IdToken | undefined>
  getTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string | undefined>
  logout: (options?: LogoutOptions) => void
}

export const Auth0Context = React.createContext<Context | null>(null)
export const useAuth0 = () => useContext(Auth0Context)!

export const storageTokenKeyName = "accessToken"

export type Auth0ProviderProps = {
  onRedirectCallback?: OnRedirectCallback
} & Auth0ClientOptions

export const Auth0Provider: React.FC<Auth0ProviderProps> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>()
  const [auth0Client, setAuth0] = useState<Auth0Client>()
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState<string>()
  const [user, setUser] = useState<User>()

  const [searchParams] = useSearchParams()

  const allowedParams = useMemo(() => {
    const token = searchParams.get("token") as AllowedParam
    const userId = searchParams.get("userId") as AllowedParam
    const isInvitation = searchParams.get("isInvitation") as AllowedParam
    const email = searchParams.get("email") as AllowedParam
    const tenantId = searchParams.get("tenantId") as AllowedParam
    const formMode = searchParams.get("formMode") ?? "login"
    const return_url = searchParams.get("returnUrl") as AllowedParam

    return {
      return_url,
      token,
      userId,
      isInvitation,
      formMode,
      email,
      tenantId,
    }
  }, [searchParams])

  const [returnUrl, setReturnUrl] = useState<string | undefined>()

  // redirect user to login if they are not logged in
  // basically forcing login here
  useEffect(() => {
    if (loading || isAuthenticated || !auth0Client) {
      return
    }
    (async () => {
      const targetUrl = window.location.href.replace(window.location.origin, "")
      await auth0Client.loginWithRedirect({
        appState: { targetUrl },
        ...allowedParams,
        ...initOptions,
      })
    })().catch(e => console.error("loginWithRedirect failed", e))
  }, [loading, isAuthenticated, auth0Client, allowedParams, initOptions])

  useEffect(() => {
    let isCancelled = false
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        redirect_uri: window.location.origin,
        ...initOptions,
      })

      if (!isCancelled) {
        setAuth0(auth0FromHook)
        if (
          window.location.search.includes("code=")
          && window.location.search.includes("state=")
        ) {
          const targetUrl = window.location.href.replace(window.location.origin, "")
          const { appState } = await auth0FromHook.handleRedirectCallback(targetUrl)
          onRedirectCallback(appState)
        }
        setIsAuthenticated(await auth0FromHook.isAuthenticated())

        setLoading(false)
      }
    }
    initAuth0().catch(e => console.error("initAuth0 failed", e))
    return () => { isCancelled = true }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    (async () => {
      if (!auth0Client) return

      if (isAuthenticated) {
        const t = await auth0Client.getTokenSilently()
        setUser(await auth0Client.getUser())
        setToken(t)

        const returnUrl = searchParams.get("returnUrl") as string | undefined
        if (!!returnUrl) {
          setReturnUrl(returnUrl)
        }
        localStorage.setItem(storageTokenKeyName, t)
      } else {
        setToken(undefined)
        localStorage.removeItem(storageTokenKeyName)
      }
    })().catch(e => console.error("getTokenSilently failed", e))
  }, [auth0Client, isAuthenticated, searchParams])

  if (!auth0Client) {
    return <Fallback />
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        loading,
        token,
        user,
        returnUrl,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
