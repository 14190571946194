import { CreateCustomerPayload } from "@contextualio/contextual-silo-auth"
import { Card, CardActions, CardContent } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { FourthStep } from "../../components/SignUp/fourth-step"
import { StepperConnector } from "../../components/Stepper"
import { useAlert } from "../../context/alert"
import { useCustomer } from "../../context/customer"
import { useCustomersService } from "../../providers"

type Props = {

}

export const SignUpBusinessPage = ({ }: Props) => {
  const navigate = useNavigate()
  const { createCustomer } = useCustomersService()
  const { setAlert } = useAlert()
  const { customer, setCustomer } = useCustomer()
  const [loading, setLoading] = useState(false)

  const handleSubmitSingup = useCallback(async (data: CreateCustomerPayload) => {
    try {
      setLoading(true)
      const rsp = await createCustomer(data)
      setCustomer(rsp)
      navigate("/my-first-tenant")
    } catch (error) {
      setAlert({
        message: "Error creating business",
        severity: "error",
      })
    } finally {
      setLoading(false)
    }
  }, [createCustomer, navigate, setAlert, setCustomer])

  useEffect(() => {
    if (customer) {
      navigate("/my-first-tenant")
    }
  }, [customer, navigate])

  return (
    <>
      <StepperConnector step={3} />
      <Card variant="elevation" sx={{ minHeight: 610, minWidth: "100%" }}>
        <CardContent>
          <FourthStep loading={loading} onSubmit={handleSubmitSingup} />
        </CardContent>
        <CardActions />
      </Card>
    </>
  )
}
