import { Tenant } from "@contextualio/contextual-silo-auth"
import { Card, CardActions, CardContent } from "@mui/material"
import { useCallback, useEffect, useState } from "react"

import { DifferentTenantSelector } from "../../components/Tenants/different-tenant"
import { NoTenants } from "../../components/Tenants/no-tenants"
import { useTenants } from "../../context/tenant"
import { handleRedirect } from "../../utils/navigation"

export const TenantsPage = () => {
  const { tenants } = useTenants()

  const [data, setData] = useState<Tenant>()

  useEffect(() => {
    /* If there is only one tenant
    do a redirect to that tenant baseDomain */
    if (tenants && tenants.length === 1) {
      return handleRedirect(tenants[0])
    }
  }, [tenants])

  const handleChange = useCallback((tenant: Tenant) => {
    setData(tenant)
    handleRedirect(tenant)
  }, [])

  return (
    <Card variant="elevation" sx={{ minWidth: "100%" }}>
      <CardContent>
        <NoTenants hidden={!!tenants?.length} />
        <DifferentTenantSelector hidden={!tenants} value={data} onChange={handleChange} tenants={tenants} />
      </CardContent>
      <CardActions />
    </Card>
  )
}
