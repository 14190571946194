import { Tenant } from "@contextualio/contextual-silo-auth"
import React, { PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useTenantsService } from "../providers"
import { handleRedirect } from "../utils/navigation"
import { isValidUser } from "../utils/users"
import { useUser } from "./user"

type TenantsContext = {
  loading: boolean
  tenants?: Tenant[]
  fetchTenants: () => Promise<void>
}

export const TenantsContext = React.createContext<TenantsContext | null>(null)
export const useTenants = () => useContext(TenantsContext)!

type Props = PropsWithChildren & {
  fallback: ReactElement
}

export const TenantsProvider = ({ children, fallback }: Props) => {
  const navigate = useNavigate()
  const { user } = useUser()
  const { listTenants } = useTenantsService()

  const [loading, setLoading] = useState(true)

  const [tenants, setTenants] = useState<Tenant[]>()

  const disableRedirect = useMemo(() =>
    window.location.pathname === "/business-signup" ||
    window.location.pathname === "/my-first-tenant" ||
    window.location.pathname === "/new-tenant"
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [window.location.pathname])

  const fetchTenants = useCallback(async () => {
    try {
      setLoading(true)
      const { items } = await listTenants()
      setTenants(items)

      if (items?.length > 0 && !disableRedirect) {
        if (items.length === 1) {
          return handleRedirect(items[0])
        }

        return navigate("/tenants")
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [disableRedirect, listTenants, navigate])

  useEffect(() => {
    if (!tenants && !!user && isValidUser(user)) {
      fetchTenants()
    } else {
      setLoading(false)
    }
  }, [fetchTenants, tenants, user])

  if (loading) {
    return <>{fallback}</>
  }

  return (
    <TenantsContext.Provider value={{ loading, tenants, fetchTenants }}>
      {children}
    </TenantsContext.Provider>
  )
}
