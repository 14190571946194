import { Card, CardActions, CardContent } from "@mui/material"

import { FifthStep } from "../../components/SignUp/fifth-step"
import { StepperConnector } from "../../components/Stepper"

type Props = {
}

export const MyFirstTenantPage = ({ }: Props) => {
  return (
    <>
      <StepperConnector step={4} />
      <Card variant="elevation" sx={{ minHeight: 610, minWidth: "100%" }}>
        <CardContent>
          <FifthStep />
        </CardContent>
        <CardActions />
      </Card>
    </>
  )
}
