import { ProductFruits as PF } from "react-product-fruits"

import { useAuth0 } from "./auth0"

export type ProductFruitsProps = Parameters<typeof PF>[0]

export const ProductFruits = (props: Omit<ProductFruitsProps, "user">) => {
  const { user } = useAuth0()

  if (!user) return null

  return (
    <PF
      user={{
        username: user.email ?? "unknown",
      }}
      {...props}
    />
  )
}
