import { CreateUserPayload } from "@contextualio/contextual-silo-auth"
import { Card, CardActions, CardContent } from "@mui/material"
import { useCallback, useState } from "react"

import { SecondStep } from "../../components/SignUp/second-step"
import { StepperConnector } from "../../components/Stepper"
import { useAlert } from "../../context/alert"
import { useAuth0 } from "../../context/auth0"
import { useUser } from "../../context/user"
import { useUsersService } from "../../providers"

type Props = {
}

export const InvitationsPage = ({ }: Props) => {
  const { returnUrl } = useAuth0()
  const { createUser } = useUsersService()
  const { setAlert } = useAlert()
  const { setUser } = useUser()

  const [loading, setLoading] = useState(false)

  const handleSubmitSingup = useCallback(async (data: CreateUserPayload) => {
    try {
      setLoading(true)
      const rsp = await createUser({
        ...data,
        signupComplete: new Date().toISOString(),
        returnUrl: window.location.origin,
      })
      setUser(rsp)
      return window.location.replace(returnUrl!)
    } catch (error) {
      setLoading(false)
      setAlert({
        message: "Error creating user account",
        severity: "error",
      })
    }
  }, [createUser, returnUrl, setAlert, setUser])

  return (
    <>
      <StepperConnector step={1} totalSteps={2} />
      <Card variant="elevation" sx={{ minHeight: 610, minWidth: "100%" }}>
        <CardContent>
          <SecondStep loading={loading} onSubmit={handleSubmitSingup} />
        </CardContent>
        <CardActions />
      </Card>
    </>
  )
}
