import { useMemo } from "react"

import { useSettings } from "../../context/theme"

export const ContextualLogo = () => {
  const { mode } = useSettings()

  const fill = useMemo(() => mode === "light" ? "#461E52" : "#FFF", [mode])

  return (
    <svg width="180" height="66" viewBox="0 0 180 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_413_118942)">
        <path d="M44.6327 6.25977C43.1127 6.25977 41.4589 6.70034 40.2785 7.98602V7.32347C40.2785 6.86762 39.9089 6.49808 39.4531 6.49808H36.8835C36.4276 6.49808 36.0581 6.86762 36.0581 7.32347V11.2102C37.2385 12.6581 38.1134 14.3964 38.5468 16.1707C39.0701 18.3155 38.9343 20.3442 38.1533 22.0664H39.4531C39.9089 22.0664 40.2785 21.6969 40.2785 21.241V13.1988C40.2785 10.4573 42.134 9.81441 43.3504 9.81441C44.5668 9.81441 46.4543 10.4573 46.4543 13.1988V21.241C46.4543 21.6969 46.8238 22.0664 47.2797 22.0664H49.8493C50.3051 22.0664 50.6747 21.6969 50.6747 21.241V12.556C50.6747 7.78376 47.433 6.25977 44.6327 6.25977Z" fill={fill} />
        <path d="M58.7278 6.49783V2.55098C58.7278 2.09513 58.3582 1.72559 57.9024 1.72559H55.3348C54.8789 1.72559 54.5094 2.09513 54.5094 2.55098V6.49783H51.4293C51.0619 6.49783 50.8645 6.95531 51.0845 7.24957C51.4658 7.7596 51.8216 8.37853 52.0996 9.12366C52.228 9.46763 52.5469 9.71203 52.914 9.71203H54.5094V17.5643C54.5094 20.4421 55.9595 22.0662 59.0653 22.0662H61.0422C61.4981 22.0662 61.8676 21.6966 61.8676 21.2408V19.6093C61.8676 19.1534 61.4981 18.7839 61.0422 18.7839H60.821C59.8423 18.7839 58.7278 18.7158 58.7278 16.8874V9.71203H61.713C62.037 9.71203 62.3282 9.52098 62.4777 9.23355C62.8519 8.51405 63.3078 7.85608 63.8359 7.26973C64.0911 6.98633 63.9004 6.49783 63.519 6.49783H58.7278Z" fill={fill} />
        <path d="M78.4857 14.2829C78.4857 9.64685 75.6495 6.19434 71.0256 6.19434C66.4018 6.19434 63.2959 9.64685 63.2959 14.2829C63.2959 18.919 66.4337 22.4055 71.1934 22.4055C73.7111 22.4055 75.8557 21.4461 77.2502 19.8578C77.5448 19.5222 77.4558 19.0156 77.0953 18.752L75.5272 17.6053C75.1637 17.3396 74.6598 17.4224 74.3216 17.7196C73.6918 18.2731 72.707 18.7848 71.1934 18.7848C69.2699 18.7848 67.75 17.5332 67.3465 15.4344H77.6603C78.1161 15.4344 78.4857 15.0649 78.4857 14.609V14.2829ZM67.4124 12.7609C67.8179 10.7964 69.1002 9.54472 71.0256 9.54472C72.9511 9.54472 74.1654 10.9325 74.5349 12.7609H67.4124Z" fill={fill} />
        <path d="M93.8191 7.15014C94.0134 6.87689 93.818 6.49829 93.4828 6.49829H89.8148C89.5477 6.49829 89.2971 6.62756 89.1423 6.84521L86.2832 10.864L83.4242 6.84521C83.2693 6.62756 83.0187 6.49829 82.7516 6.49829H79.0847C78.7488 6.49829 78.5536 6.87805 78.7491 7.15116L83.8524 14.2825L78.7491 21.4138C78.5536 21.6869 78.7488 22.0667 79.0847 22.0667H82.7516C83.0187 22.0667 83.2693 21.9374 83.4242 21.7197L86.2832 17.7009L89.1423 21.7197C89.2971 21.9374 89.5477 22.0667 89.8148 22.0667H93.4828C93.818 22.0667 94.0134 21.688 93.8191 21.4148L88.7479 14.2825L93.8191 7.15014Z" fill={fill} />
        <path d="M101.77 9.71203V16.8874C101.77 18.7158 102.885 18.7839 103.864 18.7839H104.085C104.541 18.7839 104.91 19.1534 104.91 19.6093V21.2408C104.91 21.6966 104.541 22.0662 104.085 22.0662H102.108C99.0021 22.0662 97.55 20.4421 97.55 17.5643V9.71203H95.0522C94.7169 9.71203 94.5216 9.33346 94.7158 9.06021L96.2906 6.84498C96.4454 6.62719 96.6961 6.49783 96.9633 6.49783H97.55V2.55098C97.55 2.09513 97.9196 1.72559 98.3754 1.72559H100.945C101.401 1.72559 101.77 2.09513 101.77 2.55098V6.49783H104.718C105.174 6.49783 105.543 6.86737 105.543 7.32322V8.88663C105.543 9.34249 105.174 9.71203 104.718 9.71203H101.77Z" fill={fill} />
        <path d="M118.762 6.49829C118.307 6.49829 117.937 6.86783 117.937 7.32369V15.3659C117.937 18.1075 116.113 18.7503 114.899 18.7503C113.685 18.7503 111.759 18.1075 111.759 15.3659V7.32369C111.759 6.86783 111.39 6.49829 110.934 6.49829H108.366C107.91 6.49829 107.541 6.86783 107.541 7.32369V16.0428C107.541 20.815 110.815 22.337 113.617 22.337C115.135 22.337 116.755 21.8984 117.937 20.6448V21.2413C117.937 21.6971 118.307 22.0667 118.762 22.0667H121.332C121.788 22.0667 122.157 21.6971 122.157 21.2413V7.32369C122.157 6.86783 121.788 6.49829 121.332 6.49829H118.762Z" fill={fill} />
        <path d="M137.092 6.49803C136.636 6.49803 136.267 6.86757 136.267 7.32342V8.1562C135.22 6.97265 133.768 6.26172 131.811 6.26172C127.556 6.26172 124.45 9.57805 124.45 14.2822C124.45 18.9864 127.556 22.3367 131.811 22.3367C133.768 22.3367 135.22 21.6278 136.267 20.4423V21.241C136.267 21.6968 136.636 22.0664 137.092 22.0664H139.66C140.116 22.0664 140.485 21.6968 140.485 21.241V7.32342C140.485 6.86757 140.116 6.49803 139.66 6.49803H137.092ZM132.418 18.3435C130.089 18.3435 128.535 16.6173 128.535 14.2822C128.535 11.9472 130.089 10.2209 132.418 10.2209C134.747 10.2209 136.267 11.9472 136.267 14.2822C136.267 16.6173 134.747 18.3435 132.418 18.3435Z" fill={fill} />
        <path d="M144.482 0.0683594C144.026 0.0683594 143.657 0.437902 143.657 0.893756V21.2417C143.657 21.6976 144.026 22.0671 144.482 22.0671H147.053C147.508 22.0671 147.878 21.6984 147.878 21.243L147.91 0.89503C147.911 0.438679 147.541 0.0683594 147.084 0.0683594H144.482Z" fill={fill} />
        <path d="M152.345 18.75C151.367 18.75 150.624 19.4609 150.624 20.4763C150.624 21.4916 151.367 22.2025 152.345 22.2025C153.324 22.2025 154.067 21.4575 154.067 20.4763C154.067 19.495 153.358 18.75 152.345 18.75Z" fill={fill} />
        <path d="M157.862 6.49839C157.407 6.49839 157.037 6.86793 157.037 7.32379V21.2414C157.037 21.6972 157.407 22.0667 157.862 22.0667H159.25C159.705 22.0667 160.075 21.6972 160.075 21.2414V7.32379C160.075 6.86793 159.705 6.49839 159.25 6.49839H157.862ZM158.557 -0.000106812C157.678 -0.000106812 156.767 0.608689 156.767 1.72615C156.767 2.84361 157.678 3.5205 158.557 3.5205C159.436 3.5205 160.313 2.80957 160.313 1.72615C160.313 0.642734 159.434 -0.000106812 158.557 -0.000106812Z" fill={fill} />
        <path d="M171.038 6.19434C166.347 6.19434 163.073 9.57876 163.073 14.2829C163.073 18.9871 166.347 22.3715 171.038 22.3715C175.73 22.3715 178.938 18.919 178.938 14.2829C178.938 9.64685 175.698 6.19434 171.038 6.19434ZM171.038 19.4617C168.068 19.4617 166.111 17.2268 166.111 14.2829C166.111 11.3391 168.068 9.13819 171.038 9.13819C174.008 9.13819 175.934 11.305 175.934 14.2829C175.934 17.2608 173.976 19.4617 171.038 19.4617Z" fill={fill} />
        <path d="M12.8333 18.3462C12.4864 17.792 11.6019 17.6321 11.0169 17.9239C10.51 18.1766 9.86636 18.3434 9.03209 18.3434C6.70319 18.3434 5.1153 16.6171 5.1153 14.2821C5.1153 11.947 6.70319 10.2208 9.03209 10.2208C9.68093 10.2208 10.2195 10.3407 10.6676 10.5247C11.2646 10.7699 12.0853 10.5248 12.3285 9.92701C12.5441 9.39723 12.8103 8.89571 13.1263 8.42564C13.3904 8.03269 13.3331 7.48639 12.9228 7.25013C11.7864 6.59578 10.4344 6.22754 8.93022 6.22754C4.23847 6.22754 0.998779 9.57792 0.998779 14.2821C0.998779 18.9862 4.23847 22.3706 8.93022 22.3706C10.5224 22.3706 12.0618 21.7344 13.2873 20.9073C13.8161 20.5504 13.8958 19.8313 13.5145 19.3199C13.2724 18.9952 13.041 18.6779 12.8333 18.3462Z" fill={fill} />
        <path d="M18.0629 13.8594C18.1352 9.35776 21.5639 5.93649 26.1466 6.00893C30.6984 6.08137 34.0178 9.61234 33.9455 14.1119C33.8733 18.6116 30.4445 22.0349 25.8927 21.9625C21.341 21.89 17.9907 18.359 18.0629 13.8574V13.8594ZM25.9547 18.0217C28.0912 18.0548 29.788 16.5294 29.8273 14.0478C29.8665 11.5662 28.2192 9.98489 26.0827 9.95177C23.9461 9.91866 22.2183 11.442 22.1791 13.9257C22.1399 16.4094 23.7872 17.9886 25.9547 18.0217Z" fill={fill} />
        <path d="M17.5386 14.8383C18.6038 16.7093 20.8642 18.5969 23.7521 19.8077C26.5595 20.9875 29.3091 21.2524 31.2289 20.6977L33.9951 22.9661C31.2929 24.4874 27.0302 24.506 22.7509 22.7116C18.3705 20.8716 15.1502 17.6262 14.1284 14.4347L17.5386 14.8362V14.8383Z" fill="#ED3B50" />
        <path d="M36.5589 20.0089C36.3009 20.7561 35.8798 21.406 35.3307 21.9586L32.9547 20.1041C34.2882 18.5373 35.0892 16.4821 35.1243 14.1805C35.1284 13.9425 35.1243 13.7086 35.1098 13.4768V13.4375C36.6498 15.619 37.2608 17.9764 36.5589 20.0089Z" fill="#FFC700" />
        <path d="M20.5421 6.54907C18.4985 8.02272 17.1422 10.345 16.878 13.0729L13.794 12.7003C13.7465 11.9676 13.8353 11.2494 14.0685 10.5664C14.8674 8.26281 17.52 6.86574 20.5421 6.54907Z" fill="#4660C9" />
      </g>
      <defs>
        <clipPath id="clip0_413_118942">
          <rect width="179.937" height="26" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  )
}
