import { Step, StepConnector, stepConnectorClasses, Stepper, styled } from "@mui/material"
import { useCallback } from "react"

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#74abff",
      backgroundColor: "#74abff",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#74abff",
      backgroundColor: "#74abff",
    },
  },
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#312D4B",
      backgroundColor: "#312D4B",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[800],
    borderTopWidth: 3,
    borderRadius: 5,
    height: "10px",
    width: "100%",
  },
}))

type Props = {
  step: number
  totalSteps?: number
}

export const StepperConnector = ({ step, totalSteps = 5 }: Props) => {
  const renderSteps = useCallback(() => {
    const steps = []
    for (let index = 0; index < totalSteps; index++) {
      steps.push(<Step key={index} />)
    }
    return steps
  }, [totalSteps])

  return (
    <Stepper sx={{ width: "350px" }} activeStep={step} connector={<QontoConnector />}>
      {renderSteps().map(step => (step))}
    </Stepper>
  )
}
