import { User } from "@contextualio/contextual-silo-auth"
import React, { Dispatch, PropsWithChildren, ReactElement, SetStateAction, useCallback, useContext, useEffect, useState } from "react"

import { useUsersService } from "../providers"
import { DeepPartial } from "../types/pages"

type UserContext = {
  loading: boolean
  user?: DeepPartial<User>
  setUser: Dispatch<SetStateAction<DeepPartial<User> | undefined>>
  fetchUser: () => Promise<void>
}

export const UserContext = React.createContext<UserContext | null>(null)
export const useUser = () => useContext(UserContext)!

type Props = PropsWithChildren & {
  fallback: ReactElement
}

export const UserProvider = ({ children, fallback }: Props) => {
  const [loading, setLoading] = useState(true)

  const { getUser } = useUsersService()

  const [user, setUser] = useState<DeepPartial<User>>()
  const fetchUser = useCallback(async () => {
    setLoading(true)
    try {
      const rsp = await getUser()
      setUser(rsp)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [getUser])

  useEffect(() => {
    if (!user) {
      fetchUser()
    }
  }, [fetchUser, user])

  if (loading) {
    return <>{fallback}</>
  }

  return (
    <UserContext.Provider value={{ loading, user, setUser, fetchUser }}>
      {children}
    </UserContext.Provider>
  )
}
