import { PropsWithChildren, ReactElement } from "react"

import { useAuth0 } from "../../context/auth0"
import { useCustomer } from "../../context/customer"
import { useUser } from "../../context/user"
import { InvitationGuard } from "./invitations"
import { SignupGuard } from "./signup"

type Props = PropsWithChildren & {
  fallback: ReactElement
}

export const Guard = ({ children }: Props) => {
  const { returnUrl } = useAuth0()
  const { user } = useUser()
  const { customer } = useCustomer()

  if (returnUrl) {
    return <InvitationGuard user={user}>{children}</InvitationGuard>
  } else {
    return <SignupGuard user={user} customer={customer}>{children}</SignupGuard>
  }
}
