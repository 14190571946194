import { Card, CardActions, CardContent } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { MouseEvent } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { ThirdStep } from "../../components/SignUp/third-step"
import { StepperConnector } from "../../components/Stepper"
import { useAlert } from "../../context/alert"
import { useAuth0 } from "../../context/auth0"
import { useUser } from "../../context/user"
import { useSingupService } from "../../providers"

type Props = {
  totalSteps?: number
}

export const VerifyPage = ({ totalSteps }: Props) => {
  const navigate = useNavigate()
  const { user, fetchUser } = useUser()
  const { returnUrl } = useAuth0()
  const [searchParams] = useSearchParams()

  const { verifyEmail, resendVerifyEmail } = useSingupService()
  const { setAlert } = useAlert()

  const [loading, setLoading] = useState(false)

  const token = useMemo<string | null>(() => searchParams.get("token"), [searchParams])
  const userId = useMemo<string | null>(() => searchParams.get("userId"), [searchParams])

  useEffect(() => {
    const verify = async () => {
      if (!token || !userId) {
        return
      }

      if (userId !== user?.id) {
        setAlert({
          message: "User does not match the current user, please use the correct email",
          severity: "warning",
        })

        return
      }

      try {
        setLoading(true)
        await verifyEmail({ token, ...(!!returnUrl && { returnUrl }) })
        await fetchUser()
      } catch (error) {
        setAlert({
          severity: "error",
        })
      } finally {
        setLoading(false)
      }
    }

    verify()
  }, [fetchUser, navigate, returnUrl, setAlert, token, user?.id, userId, verifyEmail])

  const handleResendEmailVerification = useCallback(async (event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    event.preventDefault()

    try {
      await resendVerifyEmail({ ...(!!returnUrl && { returnUrl }) })
      setAlert({
        severity: "success",
        message: "Email sent, check your email",
      })
    } catch (error) {
      setAlert({
        severity: "error",
        message: "Not able to re-send email",
      })
    }
  }, [resendVerifyEmail, returnUrl, setAlert])

  return (
    <>
      <StepperConnector step={2} totalSteps={totalSteps} />
      <Card variant="elevation" sx={{ minHeight: 610, minWidth: "100%" }}>
        <CardContent>
          <ThirdStep loading={loading} onResendEmail={handleResendEmailVerification} />
        </CardContent>
        <CardActions />
      </Card>
    </>
  )
}
