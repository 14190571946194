import { Customer, User } from "@contextualio/contextual-silo-auth"
import { PropsWithChildren, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { useTenants } from "../../context/tenant"
import { DeepPartial } from "../../types/pages"
import { isValidUser } from "../../utils/users"

type Props = PropsWithChildren & {
  user?: DeepPartial<User>
  customer?: DeepPartial<Customer>
}

export const SignupGuard = ({ user, customer, children }: Props) => {
  const navigate = useNavigate()
  const { tenants } = useTenants()

  const disableSignupGuard = useMemo(() =>
    window.location.pathname === "/new-tenant" ||
    window.location.pathname === "/tenants"
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [window.location.pathname])

  useEffect(() => {
    if (disableSignupGuard) return

    if (user) {
      if (!!customer && !!isValidUser(user)) {
        return navigate("/my-first-tenant")
      }

      if (!customer && !!isValidUser(user)) {
        return navigate("/business-signup")
      }

      if (!!user.termsAccepted) {
        return navigate("/verify")
      }
    }

    return navigate("/signup")
  }, [customer, disableSignupGuard, navigate, tenants, user])

  return <>{children}</>
}
