import { Grid, Stack, Typography } from "@mui/material"

import { RouterLink } from "../Link"
type Props = {
  hidden: boolean
}

export const NoTenants = ({ hidden }: Props) => {

  if (hidden) return null

  return (
    <>
      <Typography variant="h5">
        Welcome back.
      </Typography>
      <Stack spacing={3} justifyContent={"center"}>
        <Typography variant="body1">
          Oh no. It looks like you don’t have access to any Contextual tenants. Contact the administrator of a tenant in order to be invited to access the tenant.
        </Typography>
        <Grid container justifyContent={"center"} spacing={1}>
          <Grid item>
            <Typography variant="body1">
              Or
            </Typography>
          </Grid>
          <Grid item>
            <RouterLink to={"/new-tenant"}>
              create your own tenant.
            </RouterLink>
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}
