import { Customer } from "@contextualio/contextual-silo-auth"
import React, { Dispatch, PropsWithChildren, ReactElement, SetStateAction, useCallback, useContext, useEffect, useState } from "react"

import { useCustomersService } from "../providers"
import { DeepPartial } from "../types/pages"
import { isValidUser } from "../utils/users"
import { useUser } from "./user"

type CustomerContext = {
  loading: boolean
  customer?: DeepPartial<Customer>
  setCustomer: Dispatch<SetStateAction<DeepPartial<Customer> | undefined>>
  fetchCustomer: () => Promise<void>
}

export const CustomerContext = React.createContext<CustomerContext | null>(null)
export const useCustomer = () => useContext(CustomerContext)!

type Props = PropsWithChildren & {
  fallback: ReactElement
}

export const CustomerProvider = ({ children, fallback }: Props) => {
  const { user } = useUser()
  const { getCustomer } = useCustomersService()

  const [loading, setLoading] = useState(true)

  const [customer, setCustomer] = useState<DeepPartial<Customer>>()

  const fetchCustomer = useCallback(async () => {
    try {
      setLoading(true)
      const rsp = await getCustomer()
      setCustomer(rsp)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [getCustomer])

  useEffect(() => {
    if (!customer && !!user && isValidUser(user)) {
      fetchCustomer()
    } else {
      setLoading(false)
    }
  }, [customer, fetchCustomer, user])

  if (loading) {
    return <>{fallback}</>
  }

  return (
    <CustomerContext.Provider value={{ loading, customer, setCustomer, fetchCustomer }}>
      {children}
    </CustomerContext.Provider>
  )
}
