import { z, ZodIssue } from "zod"

export const getFormErrors = (schema: z.ZodType, data: unknown) => {
  const parsed = schema.safeParse(data)
  if (!parsed.success) {
    return parsed.error.errors
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueByKey = (data: any, key: string) => {
  if (!key) return undefined

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const recurse = (data: Record<string, any>, splitKey: string[]): any => {
    const [currentKey, ...rest] = splitKey
    const rv = data?.[currentKey]

    if (!rv) return undefined

    if (rest.length === 0) return rv
    return recurse(rv, rest)
  }
  return recurse(data, key.split("."))
}

export const findErrorByName = (errors: ZodIssue[] | undefined, errorName: string) => errors?.find(error => error.path.join(".") === errorName)

export const getErrorMessage = (error?: ZodIssue) => error?.message
