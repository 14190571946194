import { LoadingButton as MuiLoadingButton } from "@mui/lab"
import { Button as MuiButton, ButtonProps } from "@mui/material"

type Props = ButtonProps & {
  loading?: boolean
}

export const CustomButton = ({ loading = false, children, ...rest }: Props) => {
  if (loading) {
    return <MuiLoadingButton loading disabled {...rest}>{children}</MuiLoadingButton>
  }

  return <MuiButton {...rest}>{children}</MuiButton>
}
