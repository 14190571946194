import env from "@beam-australia/react-env"
import axios, { AxiosInstance } from "axios"
import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from "react"

import { useAuth0 } from "./auth0"

const baseURL = env("API_HOST")

type Context = {
  instance: AxiosInstance
}

export const AxiosContext = React.createContext<Context | null>(null)
export const useAxios = () => useContext(AxiosContext)!.instance

export type AxiosProviderProps = {
  splash: React.ReactNode
}

export const AxiosProvider = ({ splash, children }: PropsWithChildren<AxiosProviderProps>) => {
  const { token } = useAuth0()
  const instance = useMemo(() => axios.create({
    baseURL: baseURL + "/api/v1",
    headers: {
      "Content-Type": "application/json",
    },
  }), [])
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    let i: number | undefined = undefined
    if (token) {
      i = instance.interceptors.request.use(
        config => {
          config.headers = { Authorization: `Bearer ${token}` }
          return config
        },
        error => {
          return Promise.reject(error)
        },
      )
      setIsReady(true)
    }
    return () => {
      if (i !== undefined) {
        instance.interceptors.request.eject(i)
      }
    }
  }, [instance, token])

  if (!isReady) return <>{splash}</>

  return (
    <AxiosContext.Provider value={{ instance }}>
      {children}
    </AxiosContext.Provider>
  )
}
