import { Stack, Typography } from "@mui/material"

export const Page404 = () => {
  return (
    <Stack justifyContent={"center"}>
      <Typography variant="h1">404</Typography>
      <Typography variant="h5">Page not found</Typography>
    </Stack>
  )
}
