import { createTheme, PaletteMode, PaletteOptions, Theme } from "@mui/material"
import { ThemeProvider } from "@mui/material"
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"

const paletteModeKey = "mode"

const isPaletteMode = (mode: string): mode is PaletteMode => mode === "light" || mode === "dark"

type SettingsContextProps = {
  mode: PaletteMode
  setMode: (mode: PaletteMode) => void
  theme: Theme
}

export const SettingsContext = createContext<SettingsContextProps | null>(null)
export const useSettings = () => useContext(SettingsContext)!

export const SettingsProvider = ({ children }: PropsWithChildren) => {
  const [searchParams] = useSearchParams()

  const urlMode = useMemo<string | undefined>(() => searchParams.get("mode") as string | undefined, [searchParams])
  const localStorageMode = useMemo<string | undefined>(() => window.localStorage.getItem(paletteModeKey) as string | undefined, [])

  const [mode, setMode] = useState<Theme["palette"]["mode"]>(
    urlMode && isPaletteMode(urlMode) ?
      urlMode : !!localStorageMode && isPaletteMode(localStorageMode) ?
        localStorageMode : "dark",
  )

  useEffect(() => {
    if (!!urlMode) {
      window.localStorage.setItem(paletteModeKey, urlMode)
    }
  }, [urlMode])

  const palette = useMemo<PaletteOptions>(() => ({
    background: {
      paper: mode === "light" ? "#ffffff" : "#1d1d1d",
      default: mode === "light" ? "#f4f5fa" : "#141414",
    },
    mode: mode,
    primary: {
      light: "#96c5ff",
      main: "#74abff",
      dark: "#548de5",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#9c9fa4",
      main: "#8a8d93",
      dark: "#777b82",
      contrastText: "#ffffff",
    },
    success: {
      light: "#6ad01f",
      main: "#56ca00",
      dark: "#4cb200",
      contrastText: "#ffffff",
    },
    error: {
      light: "#ff6166",
      main: "#ff4c51",
      dark: "#e04347",
      contrastText: "#ffffff",
    },
    warning: {
      light: "#ffca64",
      main: "#ffb400",
      dark: "#e09e00",
      contrastText: "#ffffff",
    },
    info: {
      light: "#32baff",
      main: "#16b1ff",
      dark: "#139ce0",
      contrastText: "#ffffff",
    },
  }), [mode])

  const theme = useMemo(() => createTheme({
    palette,
    breakpoints: {
      values: {
        xs: 0,
        sm: 500,
        md: 992,
        lg: 1200,
        xl: 1920,
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          "root": {
            backgroundImage: "none",
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          sx: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "90vh",
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          margin: "normal",
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            textTransform: "none",
            paddingLeft: "5px",
            paddingRight: "5px",
            textAlign: "left",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            overflow: "hidden",
            padding: 0,
            borderRadius: 5,
          },
          root: {
            border: `1px solid rgba(${"231, 227, 252"}, 0.1)}`,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            margin: 20,
            minWidth: 400,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          sx: {
            mt: 3,
            mb: 2,
          },
          fullWidth: true,
          variant: "contained",
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            textTransform: "uppercase",
            "&.Mui-disabled": {
              pointerEvents: "auto",
            },
          },
          sizeSmall: {
            padding: "6px 16px",
          },
          sizeMedium: {
            padding: "8px 20px",
          },
          sizeLarge: {
            padding: "11px 24px",
          },
          textSizeSmall: {
            padding: "7px 12px",
          },
          textSizeMedium: {
            padding: "9px 16px",
          },
          textSizeLarge: {
            padding: "12px 16px",
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: "pointer",
            textDecoration: "none",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            cursor: "default",
          },
        },
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily:
        "\"Montserrat\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\"",
    },
    shadows:
      [
        "none",
        "0px 2px 1px -1px rgba(19, 17, 32, 0.2), 0px 1px 1px 0px rgba(19, 17, 32, 0.14), 0px 1px 3px 0px rgba(19, 17, 32, 0.12)",
        "0px 3px 1px -2px rgba(19, 17, 32, 0.2), 0px 2px 2px 0px rgba(19, 17, 32, 0.14), 0px 1px 5px 0px rgba(19, 17, 32, 0.12)",
        "0px 4px 8px -4px rgba(19, 17, 32, 0.42)",
        "0px 6px 18px -8px rgba(19, 17, 32, 0.56)",
        "0px 3px 5px -1px rgba(19, 17, 32, 0.2), 0px 5px 8px rgba(19, 17, 32, 0.14), 0px 1px 14px rgba(19, 17, 32, 0.12)",
        "0px 2px 10px 0px rgba(19, 17, 32, 0.1)",
        "0px 4px 5px -2px rgba(19, 17, 32, 0.2), 0px 7px 10px 1px rgba(19, 17, 32, 0.14), 0px 2px 16px 1px rgba(19, 17, 32, 0.12)",
        "0px 5px 5px -3px rgba(19, 17, 32, 0.2), 0px 8px 10px 1px rgba(19, 17, 32, 0.14), 0px 3px 14px 2px rgba(19, 17, 32, 0.12)",
        "0px 5px 6px -3px rgba(19, 17, 32, 0.2), 0px 9px 12px 1px rgba(19, 17, 32, 0.14), 0px 3px 16px 2px rgba(19, 17, 32, 0.12)",
        "0px 6px 6px -3px rgba(19, 17, 32, 0.2), 0px 10px 14px 1px rgba(19, 17, 32, 0.14), 0px 4px 18px 3px rgba(19, 17, 32, 0.12)",
        "0px 6px 7px -4px rgba(19, 17, 32, 0.2), 0px 11px 15px 1px rgba(19, 17, 32, 0.14), 0px 4px 20px 3px rgba(19, 17, 32, 0.12)",
        "0px 7px 8px -4px rgba(19, 17, 32, 0.2), 0px 12px 17px 2px rgba(19, 17, 32, 0.14), 0px 5px 22px 4px rgba(19, 17, 32, 0.12)",
        "0px 7px 8px -4px rgba(19, 17, 32, 0.2), 0px 13px 19px 2px rgba(19, 17, 32, 0.14), 0px 5px 24px 4px rgba(19, 17, 32, 0.12)",
        "0px 7px 9px -4px rgba(19, 17, 32, 0.2), 0px 14px 21px 2px rgba(19, 17, 32, 0.14), 0px 5px 26px 4px rgba(19, 17, 32, 0.12)",
        "0px 8px 9px -5px rgba(19, 17, 32, 0.2), 0px 15px 22px 2px rgba(19, 17, 32, 0.14), 0px 6px 28px 5px rgba(19, 17, 32, 0.12)",
        "0px 8px 10px -5px rgba(19, 17, 32, 0.2), 0px 16px 24px 2px rgba(19, 17, 32, 0.14), 0px 6px 30px 5px rgba(19, 17, 32, 0.12)",
        "0px 8px 11px -5px rgba(19, 17, 32, 0.2), 0px 17px 26px 2px rgba(19, 17, 32, 0.14), 0px 6px 32px 5px rgba(19, 17, 32, 0.12)",
        "0px 9px 11px -5px rgba(19, 17, 32, 0.2), 0px 18px 28px 2px rgba(19, 17, 32, 0.14), 0px 7px 34px 6px rgba(19, 17, 32, 0.12)",
        "0px 9px 12px -6px rgba(19, 17, 32, 0.2), 0px 19px 29px 2px rgba(19, 17, 32, 0.14), 0px 7px 36px 6px rgba(19, 17, 32, 0.12)",
        "0px 10px 13px -6px rgba(19, 17, 32, 0.2), 0px 20px 31px 3px rgba(19, 17, 32, 0.14), 0px 8px 38px 7px rgba(19, 17, 32, 0.12)",
        "0px 10px 13px -6px rgba(19, 17, 32, 0.2), 0px 21px 33px 3px rgba(19, 17, 32, 0.14), 0px 8px 40px 7px rgba(19, 17, 32, 0.12)",
        "0px 10px 14px -6px rgba(19, 17, 32, 0.2), 0px 22px 35px 3px rgba(19, 17, 32, 0.14), 0px 8px 42px 7px rgba(19, 17, 32, 0.12)",
        "0px 11px 14px -7px rgba(19, 17, 32, 0.2), 0px 23px 36px 3px rgba(19, 17, 32, 0.14), 0px 9px 44px 8px rgba(19, 17, 32, 0.12)",
        "0px 11px 15px -7px rgba(19, 17, 32, 0.2), 0px 24px 38px 3px rgba(19, 17, 32, 0.14), 0px 9px 46px 8px rgba(19, 17, 32, 0.12)",
      ],
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
  }), [palette])

  return (
    <SettingsContext.Provider
      value={{
        mode,
        setMode,
        theme,
      }}
    >
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </SettingsContext.Provider>
  )
}
