import { User } from "@contextualio/contextual-silo-auth"

import { DeepPartial } from "../types/pages"

export const getLastSignupVerificationsFromUser = (user: DeepPartial<User>) => {
  const { accountId, customerId, userId } = user.signupVerifications![0]
  return { accountId, customerId, ownerUserId: userId }
}

export const isValidUser = (user: DeepPartial<User>) => !!user.email && !!user.termsAccepted && !!user.verifiedAt
