import { Tenant } from "@contextualio/contextual-silo-auth"
import { MenuItem, Stack, TextField, Typography } from "@mui/material"

type Props = {
  hidden: boolean
  value?: Tenant
  tenants?: Tenant[]
  onChange: (tenant: Tenant) => void
}

export const DifferentTenantSelector = ({ hidden, tenants, value, onChange }: Props) => {

  if (hidden || !tenants || tenants.length === 0) return null

  return (
    <>
      <Typography variant="h5">
        Welcome back, let’s get you where you need to be
      </Typography>
      <Stack spacing={3} justifyContent={"center"} mt={5}>
        <TextField
          required
          label="Choose a tenant"
          name="tenant"
          autoFocus
          select
          value={value}
        >
          {tenants.sort((a, b) => a.displayName!.localeCompare(b.displayName!))?.map((tenant: Tenant) => (
            <MenuItem key={tenant.name} value={tenant.name} onClick={() => onChange(tenant)}>
              {tenant.displayName}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </>
  )
}
