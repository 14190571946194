import { Box, CircularProgress, CircularProgressProps } from "@mui/material"

export const LoadingBox = (props: CircularProgressProps) => {
  return (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CircularProgress size={50} {...props} />
    </Box>
  )
}
