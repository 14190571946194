import { z } from "zod"

export const TabOptions = z.enum(["login", "signup"])
export type TabOptions = z.infer<typeof TabOptions>

//#region AuthView
export const AuthProps = z.object({
  onAuthenticate: z.function().args(z.any()).returns(z.void()),
})
export type AuthProps = z.infer<typeof AuthProps>

export const LoginProps = AuthProps.and(
  z.object({
    hidden: z.boolean(),
  }))
export type LoginProps = z.infer<typeof LoginProps>
export const SignupProps = AuthProps.and(
  z.object({
    hidden: z.boolean(),
  }))
export type SignupProps = z.infer<typeof SignupProps>
//#endregion

//#region SignUp
export const DescriptionOptions = z.enum(["For my own product or business", "For the company I work for", "For clients"])
export const RoleOptions = z.enum(["Development", "Operations", "Quality", "Product", "Business", "Support"])

export const CreateTenantPayload = z.object({
  name: z.string(),
  tenantUrl: z.string(),
})
export type CreateTenantPayload = z.infer<typeof CreateTenantPayload>

export const SignupStep = z.object({
  loading: z.boolean().optional(),
})
export type SignupStep = z.infer<typeof SignupStep>
//#endregion


export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};
