
import { Alert as MUIAlert, Snackbar } from "@mui/material"
import { createContext, PropsWithChildren, useCallback, useContext, useState } from "react"

import { Alert, AlertValues, SnackbarAlertProps } from "../types/context"

const defaultProvider: AlertValues = {
  setAlert: () => null,
}

export const AlertContext = createContext(defaultProvider)
export const useAlert = () => useContext(AlertContext)

export const AlertProvider = ({ children }: PropsWithChildren) => {
  const [alert, setAlert] = useState<Alert | undefined>()

  return (
    <AlertContext.Provider
      value={{
        setAlert,
      }}
    >
      <SnackbarAlert alert={alert} setAlert={setAlert} />
      {children}
    </AlertContext.Provider>
  )
}

const SnackbarAlert = ({ alert, setAlert }: SnackbarAlertProps) => {

  const handleClose = useCallback((_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return
    }

    setAlert(undefined)
  }, [setAlert])

  if (!alert) {
    return <></>
  }

  return (
    <Snackbar
      open={alert !== undefined}
      onClose={handleClose}
      autoHideDuration={5000}
    >
      <MUIAlert variant="filled" onClose={handleClose} severity={alert.severity} sx={{ width: "100%" }}>
        {alert?.message ?? "Unknown error"}
      </MUIAlert>
    </Snackbar>
  )
}
