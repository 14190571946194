import { CreateSubscriptionPayload, CreateSubscriptionResponse, EmailResendVerificationPayload, EmailResendVerificationResponse, EmailVerificationPayload, EmailVerificationResponse } from "@contextualio/contextual-silo-auth"
import { AxiosInstance } from "axios"

export const SingupService = (axiosInstance: AxiosInstance) => {
  const verifyEmail = async (data: EmailVerificationPayload, signal?: AbortSignal): Promise<EmailVerificationResponse> => {
    const response = await axiosInstance.request<EmailVerificationResponse>({
      method: "post",
      url: "/verify-email",
      signal,
      data,
    })

    return response.data
  }

  const resendVerifyEmail = async (data: EmailResendVerificationPayload, signal?: AbortSignal): Promise<EmailResendVerificationResponse> => {
    const response = await axiosInstance.request<EmailResendVerificationResponse>({
      method: "put",
      url: "/verify-email",
      signal,
      data,
    })

    return response.data
  }

  const createSubscription = async (data: CreateSubscriptionPayload, signal?: AbortSignal): Promise<CreateSubscriptionResponse> => {
    const response = await axiosInstance.request<CreateSubscriptionResponse>({
      method: "post",
      url: "/subscription",
      signal,
      data,
    })

    return response.data
  }

  return {
    verifyEmail,
    resendVerifyEmail,
    createSubscription,
  }
}
