import { AppState } from "@auth0/auth0-react"
import env from "@beam-australia/react-env"
import { useCallback, useMemo } from "react"
import { Route, Routes } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import { Fallback } from "./components/Fallback"
import { Guard } from "./components/Guards"
import Layout from "./components/Layout"
import { AlertProvider } from "./context/alert"
import { Auth0Provider, useAuth0 } from "./context/auth0"
import { AxiosProvider } from "./context/axios"
import { CustomerProvider } from "./context/customer"
import { ProductFruits } from "./context/product-fruits"
import { TenantsProvider } from "./context/tenant"
import { SettingsProvider } from "./context/theme"
import { UserProvider } from "./context/user"
import { Page404 } from "./pages/404"
import { HomePage } from "./pages/Home"
import { InvitationsPage } from "./pages/Invitations"
import { MyFirstTenantPage } from "./pages/MyFirstTenant"
import { NewTenantPage } from "./pages/NewTenant"
import { SignUpPage } from "./pages/SignUp"
import { SignUpBusinessPage } from "./pages/SignUpBusiness"
import { TenantsPage } from "./pages/Tenants"
import { VerifyPage } from "./pages/Verify"

const AppRoutes = () => {
  const { returnUrl } = useAuth0()

  const totalSteps = useMemo(() => !!returnUrl ? 3 : undefined, [returnUrl])

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />

      {/* Get user info steps */}
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/verify" element={<VerifyPage totalSteps={totalSteps} />} />
      <Route path="/business-signup" element={<SignUpBusinessPage />} />
      <Route path="/my-first-tenant" element={<MyFirstTenantPage />} />
      {/* Get user info steps */}

      <Route path="/tenants" element={<TenantsPage />} />

      {/* Get user info on invite steps */}
      <Route path="/invitations" element={<InvitationsPage />} />
      {/* Get user info on invite steps */}

      <Route path="/new-tenant" element={<NewTenantPage />} />

      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}

const App = () => {
  const navigate = useNavigate()

  const onRedirectCallback = useCallback((appState?: AppState) => {
    navigate(appState?.targetUrl || window.location.pathname)
  }, [navigate])

  return (
    <SettingsProvider>
      <Auth0Provider
        client_id={env("AUTH_CLIENT_ID")}
        domain={env("AUTH_DOMAIN")}
        audience={env("AUTH_AUDIENCE")}
        onRedirectCallback={onRedirectCallback}
      >
        <ProductFruits
          workspaceCode={env("PRODUCT_FRUITS_WORKSPACE_CODE")}
          language="en"
        />
        <AxiosProvider splash={<Fallback />}>
          <UserProvider fallback={<Fallback />}>
            <TenantsProvider fallback={<Fallback />}>
              <CustomerProvider fallback={<Fallback />}>
                <Guard fallback={<Fallback />}>
                  <Layout>
                    <AlertProvider>
                      <AppRoutes />
                    </AlertProvider>
                  </Layout>
                </Guard>
              </CustomerProvider>
            </TenantsProvider>
          </UserProvider>
        </AxiosProvider>
      </Auth0Provider>
    </SettingsProvider>
  )
}

export default App
