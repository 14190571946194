import { CircularProgressProps, Container, CssBaseline } from "@mui/material"

import { LoadingBox } from "../Loading"

export const Fallback = (props: CircularProgressProps) => {
  return (
    <Container maxWidth="sm">
      <CssBaseline />
      <LoadingBox {...props} />
    </Container>

  )
}
